//Libraries
import React, { useState } from "react";

//Components
import Auxiliary from "../Auxiliary/Auxiliary";
import Toolbar from "../../components/Navigation/Toolbar/Toolbar";
import SideDrawer from "../../components/SideDrawer/SideDrawer";
import Footer from "../../components/UI/Footer/Footer";

//CSS
import classes from "./Layout.module.css";

const Layout = props => {
	const [getIsSideDrawerOpen, setIsSideDrawerOpen] = useState(false);

	const closeSideDrawerHandler = () => {
		setIsSideDrawerOpen(false);
	};

	const sideDrawerOpenHandler = () => {
		setIsSideDrawerOpen(true);
	};

	return (
		<Auxiliary>
			<div className={classes.BkgndImgContainer}>
				<Toolbar openSideDrawer={sideDrawerOpenHandler} />
			</div>
			<main>{props.children}</main>
			<SideDrawer
				openDrawer={getIsSideDrawerOpen}
				closeDrawer={closeSideDrawerHandler}
			/>
			<Footer />
		</Auxiliary>
	);
};

export default Layout;
