export const PANTRI = "PANTRI";
export const DATA_SCRAPING = "DATA_SCRAPING";
export const BLACKJACK_CARD_GAME = "BLACKJACK_CARD_GAME";
export const BATTLEFIELD = "BATTLEFIELD";
export const AVOIDANCE_GAME = "AVOIDANCE_GAME";
export const MENU_SYSTEM = "MENU_SYSTEM";
export const BURGER_BUILDER = "BURGER_BUILDER";
export const ML_SURVIVAL_TITANIC_PASSENGERS =
	"HASH_ML_SURVIVAL_TITANIC_PASSENGERS";
export const GAME_OF_LIFE = "GAME_OF_LIFE";
export const THE_NUMBER_GAME = "THE_NUMBER_GAME";
export const JUMBLE_WORD_GAME = "JUMBLE_WORD_GAME";
export const BIT_MANIPULATION = "BIT_MANIPULATION";
