//Libraries
import React, { Component } from "react";

//Components
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Backdrop from "../UI/Backdrop/Backdrop";
import NavigationItems from "../Navigation/NavigationItems/NavigationItems";

//CSS
import classes from "./SideDrawer.module.css";

class SideDrawer extends Component {
	state = {
		windowWidth: window.innerWidth
	};

	componentDidMount() {
		window.addEventListener("resize", this.handleResize);
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.handleResize);
	}

	handleResize = () => {
		this.setState({
			windowWidth: window.innerWidth
		});
	};

	render() {
		let sideDrawerClasses = [classes.SideDrawer, classes.Close];
		if (this.props.openDrawer) {
			sideDrawerClasses = [classes.SideDrawer, classes.Open];
		}

		return (
			<Auxiliary>
				<Backdrop
					show={this.props.openDrawer && this.state.windowWidth < 680}
					clicked={this.props.closeDrawer}
				/>
				<div className={sideDrawerClasses.join(" ")}>
					<h1 className={classes.H1Tags}>Portfolio</h1>
					<nav className={classes.Nav}>
						<NavigationItems click={this.props.closeDrawer} />
					</nav>
				</div>
			</Auxiliary>
		);
	}
}

export default SideDrawer;
