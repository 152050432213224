//Libraries
import React, { Component } from "react";

//CSS
import classes from "./Video.module.css";

class Video extends Component {
	render() {
		return (
			<div className={classes.VideoContainer}>
				<video
					id="myVideo"
					className={[classes.Video, this.props.class].join(" ")}
					controls
					autoPlay={this.props.start}
					muted={this.props.start}
				>
					<source src={this.props.src} type="video/mp4" />
				</video>
			</div>
		);
	}
}

export default Video;
