// Components
import React from "react";
import { NavLink } from "react-router-dom";

// CSS
import classes from "./NavigationItem.module.css";

const NavigationItem = props => {
	return (
		<li className={classes.NavigationItem}>
			<NavLink
				exact
				className={classes.NavLink}
				activeClassName={classes.CurrentPath}
				to={props.to}
				onClick={props.clicked}
				style={props.style}
			>
				{props.children}
			</NavLink>
		</li>
	);
};

export default NavigationItem;
