//Libraries
import React, { Component } from "react";

//Components
import Modal from "../UI/Modal/Modal";
import Video from "../Video/Video";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import PhotoGallery from "../PhotoGallery/PhotoGallery";

//CSS
import classes from "./Projects.module.css";

//Constants
import * as alts from "./alts";

//Assets/videos/thumbnails
import avoidanceGameThumbnail from "../../assets/videos/thumbnails/Avoidance_Game_Thumbnail.jpg";
import battleFieldThumbnail from "../../assets/videos/thumbnails/Battle_Field_Thumbnail.jpg";
import bitManipulationThumbnail from "../../assets/videos/thumbnails/Bit_Manipulation_Thumbnail.jpg";
import blackjackCardGameThumbnail from "../../assets/videos/thumbnails/Blackjack_Card_Game_Thumbnail.jpg";
import burgerBuilderThumbnail from "../../assets/videos/thumbnails/Burger_Builder_Thumbnail.jpg";
import dataScrapingThumbnail from "../../assets/videos/thumbnails/Data_Scraping_Thumbnail.jpg";
import gameOfLifeThumbnail from "../../assets/videos/thumbnails/Game_Of_Life_Thumbnail.jpg";
import jumbleWordGameThumbnail from "../../assets/videos/thumbnails/Jumble_Word_Game_Thumbnail.jpg";
import menuSystemThumbnail from "../../assets/videos/thumbnails/Menu_System_Thumbnail.jpg";
import mlSurvivalTitanicPassengersThumbnail from "../../assets/videos/thumbnails/ML_Survival_Titanic_Passengers_Thumbnail.jpg";
import pantriThumbnail from "../../assets/videos/thumbnails/Pantri_Thumbnail.jpg";
import theNumberGameThumbnail from "../../assets/videos/thumbnails/The_Number_Game_Thumbnail.jpg";

//Assets/videos
import pantriVideo from "../../assets/videos/Pantri_Android_Studio_App.mp4";
import avoidanceGameVideo from "../../assets/videos/Avoidance_Game_C_Plus_Plus.mp4";
import battleFieldVideo from "../../assets/videos/Battle_Field_C_Plus_Plus.mp4";
import bitManipulationVideo from "../../assets/videos/Bit_Manipulation_C_Plus_Plus.mp4";
import blackjackCardGameVideo from "../../assets/videos/Blackjack_Card_Game_C_Sharp.mp4";
import burgerBuilderVideo from "../../assets/videos/Burger_Builder_React_Web_App.mp4";
import dataScrapingVideo from "../../assets/videos/Data_Scraping_Python.mp4";
import gameOfLifeVideo from "../../assets/videos/Game_Of_Life_Windows_Forms.mp4";
import jumbleWordGameVideo from "../../assets/videos/Jumble_Word_Game_C_Plus_Plus.mp4";
import menuSystemVideo from "../../assets/videos/Menu_System_C_Plus_Plus.mp4";
import mlSurvivalTitanicPassengersVideo from "../../assets/videos/ML_Survival_Titanic_Passengers_Python.mp4";
import theNumberGameVideo from "../../assets/videos/The_Number_Game_C_Plus_Plus.mp4";

class Projects extends Component {
	state = {
		isViewingMedia: false,
		isVideo: false,
		mediaId: null,
		pageXOffset: null,
		pageYOffset: null
	};

	componentDidUpdate() {
		if (this.state.mediaId && !this.state.isViewingMedia) {
			this.navigateToPageSection(
				this.state.pageXOffset,
				this.state.pageYOffset
			);
			this.setState({
				mediaId: null,
				pageXOffset: null,
				pageYOffset: null
			});
		}
	}

	createImageElement = (src, alt, isVideo) => {
		const css = isVideo ? classes.VideoThumbnail : classes.Images;
		return (
			<img
				id={alt}
				className={css}
				alt={alt}
				src={src}
				onClick={() => {
					this.setState({
						isViewingMedia: true,
						isVideo: isVideo,
						mediaId: alt,
						pageXOffset: window.pageXOffset,
						pageYOffset: window.pageYOffset
					});
				}}
			/>
		);
	};

	onModalHandler = () => {
		this.setState({
			isViewingMedia: false,
			isVideo: false
		});
	};

	getVideoSrc = () => {
		switch (this.state.mediaId) {
			case alts.PANTRI:
				return pantriVideo;
			case alts.DATA_SCRAPING:
				return dataScrapingVideo;
			case alts.BLACKJACK_CARD_GAME:
				return blackjackCardGameVideo;
			case alts.BATTLEFIELD:
				return battleFieldVideo;
			case alts.AVOIDANCE_GAME:
				return avoidanceGameVideo;
			case alts.MENU_SYSTEM:
				return menuSystemVideo;
			case alts.BURGER_BUILDER:
				return burgerBuilderVideo;
			case alts.ML_SURVIVAL_TITANIC_PASSENGERS:
				return mlSurvivalTitanicPassengersVideo;
			case alts.GAME_OF_LIFE:
				return gameOfLifeVideo;
			case alts.THE_NUMBER_GAME:
				return theNumberGameVideo;
			case alts.JUMBLE_WORD_GAME:
				return jumbleWordGameVideo;
			case alts.BIT_MANIPULATION:
				return bitManipulationVideo;
			default:
				break;
		}
	};

	generateModal = () => {
		let modal, elementToPresent;
		const mediaSelected = document.getElementById(this.state.mediaId);
		if (this.state.isVideo) {
			elementToPresent = (
				<Video src={this.getVideoSrc()} start class={classes.Video} />
			);
		} else {
			elementToPresent = (
				<img
					className={classes.DisplayImage}
					alt={mediaSelected.alt}
					src={mediaSelected.src}
				/>
			);
		}
		modal = (
			<Modal
				class={classes.ModalConfig}
				show={this.state.isViewingMedia}
				clicked={this.onModalHandler}
			>
				{elementToPresent}
			</Modal>
		);
		return modal;
	};

	createVideoSection = (src, alt, description, language) => {
		return (
			<Auxiliary>
				{this.createImageElement(src, alt, true)}
				<div className={classes.VideoThumbnailDescription}>
					<p className={classes.VideoThumbnailPTags}>{description}</p>
					<p className={classes.VideoThumbnailPTags}>
						Language: {language}
					</p>
				</div>
			</Auxiliary>
		);
	};

	navigateToPageSection = (xPos, yPos) => {
		const instructions = {
			left: xPos,
			top: yPos,
			behavior: "smooth"
		};
		window.scrollTo(instructions);
	};

	render() {
		let modal = null;
		if (this.state.isViewingMedia) {
			this.navigateToPageSection(0, 350);
			modal = this.generateModal();
		}

		return (
			<div className={classes.Layout}>
				<div className={classes.InnerLayout}>
					<div className={classes.ModalContainer}>{modal}</div>
				</div>
				<PhotoGallery image={this.createImageElement} />
				<div className={classes.InnerLayout}>
					<h1 className={classes.H1VideoTag}>Videos</h1>
					<p className={classes.VideoThumbnailMessage}>
						Click on videos to watch
					</p>
					<div className={classes.VideoThumbnailContainer}>
						<div className={classes.VideoThumbnailColumn}>
							{this.createVideoSection(
								pantriThumbnail,
								alts.PANTRI,
								"Android Studio: 'Pantri' Health Food App",
								"Java"
							)}
							{this.createVideoSection(
								dataScrapingThumbnail,
								alts.DATA_SCRAPING,
								"Web/Data Scraping: Gathering Data Then Plotting Graphs For Analysis",
								"Python"
							)}
							{this.createVideoSection(
								blackjackCardGameThumbnail,
								alts.BLACKJACK_CARD_GAME,
								"Blackjack Card Game: Playing against Card Dealer Making Its Own Decisions In The Game",
								"C#"
							)}
							{this.createVideoSection(
								battleFieldThumbnail,
								alts.BATTLEFIELD,
								"Battlefield Game: 2 Player Game Each Player Gets 3 Characters To Battle With",
								"C++"
							)}
							{this.createVideoSection(
								avoidanceGameThumbnail,
								alts.AVOIDANCE_GAME,
								"Avoidance Game: Flying Through Space While Avoiding Obstacles Everywhere",
								"C++"
							)}
							{this.createVideoSection(
								menuSystemThumbnail,
								alts.MENU_SYSTEM,
								"Menu System: Key Data Structuring Core Concepts Used To Build A Menu System",
								"C++"
							)}
						</div>
						<div className={classes.VideoThumbnailColumn}>
							{this.createVideoSection(
								burgerBuilderThumbnail,
								alts.BURGER_BUILDER,
								"React.js: Customize Your Burger, Login To Fill Out Your Order Form, Send It, And Track Your Order History",
								"JavaScript"
							)}
							{this.createVideoSection(
								mlSurvivalTitanicPassengersThumbnail,
								alts.ML_SURVIVAL_TITANIC_PASSENGERS,
								"Machine Learning: Predicting The Survival Of The Titanic Passengers",
								"Python"
							)}
							{this.createVideoSection(
								gameOfLifeThumbnail,
								alts.GAME_OF_LIFE,
								"Game Of Life(Windows Forms App): Cellular Automation, Cells Will Live, Die, Or Multiply Based On Strict Rules",
								"C#"
							)}
							{this.createVideoSection(
								theNumberGameThumbnail,
								alts.THE_NUMBER_GAME,
								"The Number Game: Using Strategy To Try And Guess The Secret Number 10 Challenging Levels",
								"C#"
							)}
							{this.createVideoSection(
								jumbleWordGameThumbnail,
								alts.JUMBLE_WORD_GAME,
								"Jumble Word Game: Implemented Data Structuring In The Background And Made An Awesome Jumble Word Guessing Game",
								"C++"
							)}
							{this.createVideoSection(
								bitManipulationThumbnail,
								alts.BIT_MANIPULATION,
								"Bit Manipulation: Control the 32 Bits First Hand And Get To See What Happens Behind The Scenes",
								"C++"
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Projects;
