//Libraries
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

//Components
import HomePage from "../HomePage/HomePage";
import Projects from "../Projects/Projects";
import ContactInfo from "../ContactInfo/ContactInfo";
import AboutMe from "../AboutMe/AboutMe";
import ComingSoon from "../ComingSoon/ComingSoon";

const Routing = () => {
	return (
		<Switch>
			<Route path="/about_me" component={AboutMe} />
			<Route path="/contact_info" component={ContactInfo} />
			<Route path="/projects" component={Projects} />
			<Route path="/coming_soon" component={ComingSoon} />
			<Route exact path="/" component={HomePage} />
			<Redirect to="/" />
		</Switch>
	);
};

export default Routing;
