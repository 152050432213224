//Libraries
import React, { Component } from "react";

//CSS
import classes from "./AboutMe.module.css";

class AboutMe extends Component {
	render() {
		return (
			<div className={classes.Layout}>
				<div className={classes.InnerLayout}>
					<section className={classes.H1Container}>
						<hr className={classes.HrLine} />
						<h1 className={classes.H1Tag}>My Story</h1>
						<hr className={classes.HrLine} />
					</section>
					<div className={classes.ParallaxOne}></div>
					<div className={classes.DescriptionContainer}>
						<div className={classes.Description}>
							Hey there, my name is Calistro Vieyra. I design and
							create Angular websites. I have a keen eye for
							detail with exceptional creative, design and coding
							abilities. I like taking on new challenges. My goal
							is to deliver at 100% when working on projects.
						</div>
					</div>
					<div className={classes.ParallaxTwo}></div>
					<div className={classes.DescriptionContainer}>
						<div className={classes.Description}>
							Dynamic and responsive designs...
						</div>
					</div>
					<div className={classes.ParallaxThree}></div>
					<div className={classes.DescriptionContainer}>
						<div className={classes.Description}>
							Building modern applications using modern
							technology...
						</div>
					</div>
					<div className={classes.ParallaxFour}></div>
				</div>
			</div>
		);
	}
}

export default AboutMe;
