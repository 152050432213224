//Libraries
import React from "react";

//Components
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";

//CSS
import classes from "./ComingSoon.module.css";

//Assets
import inProgressOne from "../../assets/images/In_progress_1.jpg";
import inProgressTwo from "../../assets/images/In_progress_2.jpg";
import inProgressThree from "../../assets/images/In_progress_3.jpg";
import inProgressFour from "../../assets/images/In_progress_4.jpg";
import inProgressFive from "../../assets/images/In_progress_5.jpg";

const ComingSoon = () => {
	const createImageElement = (src, alt, cssClass) => {
		const css = cssClass ? cssClass : classes.Images;
		return <img className={css} alt={alt} src={src} />;
	};
	const createImageSection = (src, alt, description) => {
		return (
			<Auxiliary>
				{createImageElement(src, alt)}
				<div className={classes.ImageDescriptionContainer}>
					<p className={classes.ImageDescription}>{description}</p>
				</div>
			</Auxiliary>
		);
	};
	return (
		<div className={classes.Layout}>
			<div className={classes.InnerLayout}>
				<div className={classes.PositionPentagonalPrism}>
					<div className={classes.PentagonalPrismContainer}>
						<div className={classes.PentagonalPrism}>
							<div className={classes.Front}>
								{createImageElement(
									inProgressOne,
									"inProgressOne",
									classes.PentagonalPrismImages
								)}
							</div>
							<div className={classes.FrontReverse}></div>
							<div className={classes.LeftFront}>
								{createImageElement(
									inProgressTwo,
									"inProgressTwo",
									classes.PentagonalPrismImages
								)}
							</div>
							<div className={classes.LeftFrontReverse}></div>
							<div className={classes.LeftRear}>
								{createImageElement(
									inProgressThree,
									"inProgressThree",
									classes.PentagonalPrismImages
								)}
							</div>
							<div className={classes.LeftRearReverse}></div>
							<div className={classes.RightFront}>
								{createImageElement(
									inProgressFour,
									"inProgressFour",
									classes.PentagonalPrismImages
								)}
							</div>
							<div className={classes.RightFrontReverse}></div>
							<div className={classes.RightRear}>
								{createImageElement(
									inProgressFive,
									"inProgressFive",
									classes.PentagonalPrismImages
								)}
							</div>
							<div className={classes.RightRearReverse}></div>
						</div>
					</div>
				</div>
			</div>
			<div className={classes.InnerLayout}>
				<div className={classes.Container}>
					<div className={classes.NewProject}>
						{createImageSection(
							inProgressOne,
							"inProgressOne",
							"Currently working on parsing an API for unique image filtering based on user’s choice selection. The API I’m working with is from “Pho.to”, they have been kind enough to allow me to work with their filtering API. I am creating this project with React.js."
						)}
						{createImageSection(
							inProgressTwo,
							"inProgressTwo",
							"Also currently working on an automotive/social app. A place where users post their vehicle concerns and automotive technicians can freelance and pick up side jobs on their free time. Here is the login page. I am creating this project with React.js."
						)}
						{createImageSection(
							inProgressThree,
							"inProgressThree",
							"This is the user edit profile page. We are viewing the vehicle info selection tab. Users can save their vehicle information for future reference. This is especially helpful when posting a vehicle concern allowing quick and easy integration of the vehicle’s information into the post."
						)}
						{createImageSection(
							inProgressFour,
							"inProgressFour",
							"Here we are in the profile page. Demonstrating functionality of the user’s ability to make a post. You can see one post was made, the time, and day of the post, all being saved into a database."
						)}
						{createImageSection(
							inProgressFive,
							"inProgressFive",
							"Here I’m demonstrating the functionality of also being able to post comments for any individual post made."
						)}
					</div>
					<p className={classes.BottomNote}>
						I will post more videos and more photos in the projects
						section about these two projects once I am at a good
						stopping point to show you all more!
					</p>
				</div>
			</div>
		</div>
	);
};

export default ComingSoon;
