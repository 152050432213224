//Libraries
import React from "react";

//CSS
import classes from "./Footer.module.css";

//Assets
import MiniLinkedInLogo from "../../../assets/images/LI-In-Bug.png";
import githubLogo from "../../../assets/images/GitHub-Mark-64px.png";

const Footer = () => {
	return (
		<footer className={classes.Footer}>
			<div className={classes.LogoContainer}>
				<div className={classes.FooterLogoContainer}>
					<a href="https://www.linkedin.com/in/calistrovieyra">
						<img
							className={classes.FooterLogo}
							alt="LinkedIn logo"
							src={MiniLinkedInLogo}
						/>
					</a>
				</div>
				<div className={classes.FooterLogoContainer}>
					<a href="https://www.github.com/vieyracalistro">
						<img
							className={classes.FooterLogo}
							alt="Github logo"
							src={githubLogo}
						/>
					</a>
				</div>
			</div>
			<div className={classes.FooterNote}>
				<label>
					<strong>
						Click on logos to check out my LinkedIn and GitHub!
					</strong>
				</label>
			</div>
		</footer>
	);
};

export default Footer;
