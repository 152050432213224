//Libraries
import React from "react";

//CSS
import classes from "./PhotoGallery.module.css";

//Assets/images
import chickenSearch from "../../assets/images/chicken_search.jpg";
import avoidanceGame from "../../assets/images/avoidance_game.jpg";
import battleFieldGame from "../../assets/images/battlefield_game.jpg";
import blackjackBid from "../../assets/images/blackjack_bid_200.jpg";
import blackjackCardShuffle from "../../assets/images/blackjack_card_shuffle.jpg";
import blackjackGame from "../../assets/images/blackjack_game_2.jpg";
import confusionMatrix from "../../assets/images/confusion_matrix.jpg";
import conwayGameOfLife from "../../assets/images/conway_game_of_life.jpg";
import conwayGameOfLifeTwo from "../../assets/images/conway_game_of_life_2.jpg";
import developersPopulation from "../../assets/images/Developers Population.png";
import foodRecipeSearch from "../../assets/images/food_recipe_search.jpg";
import healthBMICalculator from "../../assets/images/health_bmi_calculator.jpg";
import heatMap from "../../assets/images/heat_map.jpg";
import searchDetail from "../../assets/images/search_detail.jpg";
import jumbleWordGame from "../../assets/images/jumble_word_game.jpg";
import linearMathEngineDotProduct from "../../assets/images/linear_math_engine_dot_product.jpg";
import linearMathEngineNormalize from "../../assets/images/linear_math_engine_normalize.jpg";
import menuSystem from "../../assets/images/menu_system.jpg";
import moqTest from "../../assets/images/Moq_test.jpg";
import numberGuessGame from "../../assets/images/number_guess_game.jpg";
import numberGuessGameTwo from "../../assets/images/number_guess_game_2.jpg";
import numberGuessGameThree from "../../assets/images/number_guess_game_3.jpg";
import nutritionInfo from "../../assets/images/nutrition_info.jpg";
import pythonCode from "../../assets/images/python_code.jpg";
import mlResults from "../../assets/images/ML_results.jpg";
import RYOTest from "../../assets/images/RYO_test.jpg";
import shrimpDishesSearch from "../../assets/images/shrimp_dishes_search.jpg";
import socialWebApp from "../../assets/images/Social_web_app.jpg";
import socialWebAppTwo from "../../assets/images/Social_web_app_2.jpg";
import socialWebAppThree from "../../assets/images/Social_web_app_3.jpg";
import matrixFunctions from "../../assets/images/Matrix_functions.jpg";
import vectorFunctions from "../../assets/images/Vector_functions.jpg";
import backwardsArrow from "../../assets/images/baseline_arrow_back_black_18dp.png";
import forwardArrow from "../../assets/images/baseline_arrow_forward_black_18dp.png";

const PhotoGallery = props => {
	const createPicture = (isBigPicture, src, alt, isVideo) => {
		const css = isBigPicture ? classes.BigPicture : classes.NormalPicture;
		return <div className={css}>{props.image(src, alt, isVideo)}</div>;
	};

	return (
		<div className={classes.Layout}>
			<div className={classes.InnerLayout}>
				<h1 className={classes.H1PhotoTag}>Photo Gallery</h1>
			</div>
			<div className={classes.InnerLayout}>
				<div className={classes.PhotoGalleryContainer}>
					<p className={classes.GalleryMessage}>
						Click on photos to view
					</p>
					<p className={classes.GalleryMessageTwo}>
						Scroll
						<img
							className={classes.Arrows}
							src={backwardsArrow}
							alt="backwardsArrow"
						/>
						and
						<img
							className={classes.Arrows}
							src={forwardArrow}
							alt="forwardArrow"
						/>
						to view photos
					</p>
					<section className={classes.PhotoGallery}>
						{createPicture(true, chickenSearch, "chickenSearch")}
						<div className={classes.BothColumns}>
							<div className={classes.NormalPhotoContainerTop}>
								{createPicture(
									false,
									avoidanceGame,
									"avoidanceGame"
								)}
								{createPicture(
									false,
									battleFieldGame,
									"battleFieldGame"
								)}
								{createPicture(
									false,
									blackjackBid,
									"blackjackBid"
								)}
								{createPicture(
									false,
									blackjackCardShuffle,
									"blackjackCardShuffle"
								)}
								{createPicture(
									false,
									blackjackGame,
									"blackjackGame"
								)}
								{createPicture(
									false,
									confusionMatrix,
									"confusionMatrix"
								)}
							</div>
							<div className={classes.NormalPhotoContainerBottom}>
								{createPicture(
									false,
									conwayGameOfLife,
									"conwayGameOfLife"
								)}
								{createPicture(
									false,
									conwayGameOfLifeTwo,
									"conwayGameOfLifeTwo"
								)}
								{createPicture(
									false,
									developersPopulation,
									"developersPopulation"
								)}
								{createPicture(
									false,
									foodRecipeSearch,
									"foodRecipeSearch"
								)}
								{createPicture(
									false,
									healthBMICalculator,
									"healthBMICalculator"
								)}
								{createPicture(false, heatMap, "heatMap")}
							</div>
						</div>
						{createPicture(true, searchDetail, "searchDetail")}
						<div className={classes.BothColumns}>
							<div className={classes.NormalPhotoContainerTop}>
								{createPicture(
									false,
									jumbleWordGame,
									"jumbleWordGame"
								)}
								{createPicture(
									false,
									linearMathEngineDotProduct,
									"linearMathEngineDotProduct"
								)}
								{createPicture(false, moqTest, "moqTest")}
								{createPicture(
									false,
									numberGuessGame,
									"numberGuessGame"
								)}
								{createPicture(
									false,
									numberGuessGameTwo,
									"numberGuessGameTwo"
								)}
							</div>
							<div className={classes.NormalPhotoContainerBottom}>
								{createPicture(
									false,
									linearMathEngineNormalize,
									"linearMathEngineNormalize"
								)}
								{createPicture(false, menuSystem, "menuSystem")}
								{createPicture(false, mlResults, "mlResults")}
								{createPicture(false, RYOTest, "RYOTest")}
								{createPicture(
									false,
									socialWebApp,
									"socialWebApp"
								)}
							</div>
						</div>
						{createPicture(
							true,
							shrimpDishesSearch,
							"shrimpDishesSearch"
						)}
						{createPicture(
							true,
							socialWebAppThree,
							"socialWebAppThree"
						)}
						<div className={classes.BothColumns}>
							<div className={classes.NormalPhotoContainerTop}>
								{createPicture(
									false,
									nutritionInfo,
									"nutritionInfo"
								)}
								{createPicture(false, pythonCode, "pythonCode")}
								{createPicture(
									false,
									vectorFunctions,
									"vectorFunctions"
								)}
							</div>
							<div className={classes.NormalPhotoContainerBottom}>
								{createPicture(
									false,
									socialWebAppTwo,
									"socialWebAppTwo"
								)}
								{createPicture(
									false,
									numberGuessGameThree,
									"numberGuessGameThree"
								)}
								{createPicture(
									false,
									matrixFunctions,
									"matrixFunctions"
								)}
							</div>
						</div>
					</section>
				</div>
			</div>
		</div>
	);
};

export default PhotoGallery;
