//Libraries
import React from "react";

//CSS
import classes from "./Cube.module.css";

const Cube = () => {
	return (
		<div className={classes.Layout}>
			<div className={classes.CubeContainer}>
				<div className={classes.Cube}>
					<div className={classes.FrontSide}>Calistro Vieyra</div>
					<div className={classes.BackSide}>
						Angular Website Development
					</div>
					<div className={classes.LeftSide}></div>
					<div className={classes.RightSide}>
						<span className={classes.OpeningCircle}></span>
					</div>
					<div className={classes.TopSide}>Website Designer</div>
					<div className={classes.BottomSide}>Software Engineer</div>
				</div>
			</div>
		</div>
	);
};

export default Cube;
