//Libraries
import React, { Component } from "react";

//Components
import Modal from "../UI/Modal/Modal";

//CSS
import classes from "./HomePage.module.css";

//Assets
import pdfIcon from "../../assets/images/baseline_picture_as_pdf_black_24dp.png";
import resumePdf from "../../assets/Calistro_Vieyra_Resume.pdf";
import resumeThumbnail from "../../assets/images/Calistro_Vieyra_Resume.jpg";
import agile from "../../assets/images/Agile_compressed.jpg";

class HomePage extends Component {
	state = {
		isViewingMedia: false,
		docId: null,
		pageXOffset: null,
		pageYOffset: null
	};

	componentDidUpdate() {
		if (this.state.docId && !this.state.isViewingMedia) {
			this.navigateToPageSection(
				this.state.pageXOffset,
				this.state.pageYOffset
			);
			this.setState({
				docId: null,
				pageXOffset: null,
				pageYOffset: null
			});
		}
	}

	onModalHandler = () => {
		this.setState({ isViewingMedia: false });
	};

	createImageElement = (id, src, cssClass, viewInModal) => {
		const css = cssClass ? cssClass : classes.Images;
		let clickEvent = null;
		if (viewInModal) {
			clickEvent = () =>
				this.setState({
					isViewingMedia: true,
					docId: id,
					pageXOffset: window.pageXOffset,
					pageYOffset: window.pageYOffset
				});
		}
		return (
			<img
				id={id}
				className={css}
				alt={id}
				src={src}
				onClick={clickEvent}
			/>
		);
	};

	navigateToPageSection = (xPos, yPos) => {
		window.scrollTo(xPos, yPos);
	};

	render() {
		let modal = null;
		if (this.state.isViewingMedia) {
			const imageSelected = document.getElementById(this.state.docId);
			this.navigateToPageSection(0, 330);
			modal = (
				<div className={classes.ModalSection}>
					<Modal
						class={classes.ModalConfig}
						show={this.state.isViewingMedia}
						clicked={this.onModalHandler}
					>
						<img
							className={classes.presentImage}
							alt={imageSelected.alt}
							src={imageSelected.src}
						/>
					</Modal>
				</div>
			);
		}

		return (
			<div className={classes.Layout}>
				<div className={classes.InnerLayout}>{modal}</div>
				<div className={classes.InnerLayout}>
					<div className={classes.MainDisplayBackground}>
						{this.createImageElement(
							"agile",
							agile,
							classes.MainDisplayImage,
							true
						)}
					</div>
				</div>
				<div className={classes.InnerLayout}>
					<div className={classes.ResumeContainer}>
						<div className={classes.InnerResumeCont}>
							<label className={classes.ResumeLabel}>
								<strong>Resume</strong>
							</label>
						</div>
						<div className={classes.InnerResumeCont}>
							<div className={classes.DownloadResume}>
								<hr />
								<label className={classes.DownloadLabel}>
									<strong>
										Download my complete resume:
									</strong>
								</label>
								<a
									className={classes.DownloadLink}
									download
									href={resumePdf}
								>
									{this.createImageElement(
										"pdfIcon",
										pdfIcon,
										classes.PdfIcon
									)}
								</a>
								<hr />
							</div>
						</div>
						<div className={classes.InnerResumeCont}>
							{this.createImageElement(
								"resumeThumbnail",
								resumeThumbnail,
								classes.Resume
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default HomePage;
