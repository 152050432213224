//Libraries
import React from "react";

//Components
import Backdrop from "../Backdrop/Backdrop";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";

//CSS
import classes from "./Modal.module.css";

const Modal = props => {
	const modalClass = props.show ? classes.Modal : classes.NoModal;
	return (
		<Auxiliary>
			<Backdrop show={props.show} clicked={props.clicked} />
			<div className={classes.ModalLayout}>
				<div className={classes.InnerLayout}>
					<section className={[modalClass, props.class].join(" ")}>
						{props.children}
					</section>
				</div>
			</div>
		</Auxiliary>
	);
};

export default Modal;
