//Libraries
import React, { Component } from "react";

//Components
import NavigationItem from "./NavigationItem/NavigationItem";

//CSS
import classes from "./NavigationItems.module.css";

class NavigationItems extends Component {
	render() {
		return (
			<ul className={classes.NavigationItems}>
				<NavigationItem to="/" clicked={this.props.click}>
					<span className={classes.NavItemChild}>Home</span>
				</NavigationItem>
				<NavigationItem to="/about_me" clicked={this.props.click}>
					<span className={classes.NavItemChild}>About Me</span>
				</NavigationItem>
				<NavigationItem to="/projects" clicked={this.props.click}>
					<span className={classes.NavItemChild}>Projects</span>
				</NavigationItem>
				<NavigationItem to="/coming_soon" clicked={this.props.click}>
					<span className={classes.NavItemChild}>Coming Soon</span>
				</NavigationItem>
				<NavigationItem to="/contact_info" clicked={this.props.click}>
					<span className={classes.NavItemChild}>Contact Info</span>
				</NavigationItem>
			</ul>
		);
	}
}

export default NavigationItems;
