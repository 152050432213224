//Libraries
import React from "react";

//Components
import Routing from "../Routing/Routing";
import Layout from "../../hoc/Layout/Layout";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";

//Polyfill (smooth-scrolling for unsupported browsers)
import smoothscroll from "smoothscroll-polyfill";

// kick off the polyfill!
smoothscroll.polyfill();

const App = () => {
	return (
		<Auxiliary>
			<Layout>
				<Routing />
			</Layout>
		</Auxiliary>
	);
};

export default App;
