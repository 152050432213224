//Libraries
import React from "react";

//Components
import NavigationItems from "../NavigationItems/NavigationItems";
import DrawerToggle from "../../SideDrawer/DrawerToggle/DrawerToggle";
import Cube from "../../Cube/Cube";

//CSS
import classes from "./Toolbar.module.css";

const Toolbar = props => {
	return (
		<div className={classes.Layout}>
			<div className={classes.InnerLayout}>
				<Cube />
				<div className={classes.ToolBarContainer}>
					<header className={classes.Toolbar}>
						<DrawerToggle clicked={props.openSideDrawer} />
						<nav className={classes.DesktopOnly}>
							<NavigationItems />
						</nav>
					</header>
				</div>
			</div>
		</div>
	);
};

export default Toolbar;
